import {IStoreFrontNavigationContext} from '@wix/wixstores-client-core';
import {IWixAPI, StructurePage} from '@wix/native-components-infra/dist/es/src/types/types';
import {ExtendedSiteApis, ExtendedStructurePage, ReferringPageInfo} from './sitemapUtils.types';

const findPageInSiteStructure = async (wixcodeApi: IWixAPI, pageId: string): Promise<StructurePage> => {
  const siteStructure = await wixcodeApi.site.getSiteStructure({includePageId: true});
  return siteStructure.pages.find((p) => p.id === pageId);
};

const isTemplatePage = <T extends ExtendedStructurePage>(page: T): boolean => {
  return page.type === 'template';
};

const getReferringPageByTemplatePage = async (
  wixcodeApi: IWixAPI,
  navigationContext: Pick<IStoreFrontNavigationContext, 'pagePath'>,
  templatePage: ExtendedStructurePage
): Promise<ReferringPageInfo | undefined> => {
  const {pagePath} = navigationContext;
  if (!templatePage.prefix || !pagePath || pagePath.length === 0) {
    return;
  }

  const site = wixcodeApi.site as ExtendedSiteApis;
  /* istanbul ignore if reason: need to be exposed from native-components-infra */
  if (!site.routerSitemap) {
    return;
  }

  const siteMap = await site.routerSitemap(templatePage.prefix);
  const routerPage = siteMap.find((p) => p.url === pagePath.join('/'));
  if (routerPage) {
    return {
      url: `/${templatePage.prefix}/${routerPage.url}`,
      title: routerPage.title,
      pageId: routerPage.pageName,
    };
  }
};

const getReferringPageByStaticPage = (staticPage: StructurePage): ReferringPageInfo => {
  return {
    url: staticPage.url,
    title: staticPage.name,
    pageId: staticPage.id,
  };
};

export const getReferringPage = async (
  wixcodeApi: IWixAPI,
  navigationContext: Pick<IStoreFrontNavigationContext, 'pagePath' | 'pageId'>
): Promise<ReferringPageInfo | undefined> => {
  const {pageId} = navigationContext;
  if (!pageId) {
    return;
  }
  const page = (await findPageInSiteStructure(wixcodeApi, pageId)) as ExtendedStructurePage;
  if (!page) {
    return;
  }
  if (isTemplatePage(page)) {
    return getReferringPageByTemplatePage(wixcodeApi, navigationContext, page);
  } else {
    return getReferringPageByStaticPage(page);
  }
};
