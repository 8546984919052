import {SiteStructure, StructurePage} from '@wix/native-components-infra/dist/es/src/types/types';
import {IStoreFrontNavigationContext} from '@wix/wixstores-client-core';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {getReferringPage} from './sitemap/sitemapUtils';

import {capitalizeFirstLetters} from '../commons/utils';
import {SPECS} from '../specs';
import {IProductDTO, PagePath, TranslationDictionary} from '../types/app-types';

type PageInfo = Pick<StructurePage, 'id' | 'name' | 'url'>;

export class BreadcrumbsService {
  constructor(
    private readonly siteStore: SiteStore,
    private readonly navigationContext: IStoreFrontNavigationContext,
    private readonly product: IProductDTO,
    private readonly translations: TranslationDictionary
  ) {
    //
  }
  private toPagePath(page: PageInfo, transformName: boolean): PagePath {
    return {
      name: transformName ? capitalizeFirstLetters(page.name) : page.name,
      url: page.url && `${this.siteStore.location.baseUrl}${page.url}`,
      relativeUrl: page.url,
      sdkLink: {pageId: page.id, type: 'PageLink'},
    };
  }

  private isCurrentPage(pageId: string): boolean {
    return pageId === this.siteStore.siteApis.currentPage.id;
  }

  private readonly getValidReferringPage = async (
    homepage: StructurePage,
    siteStructure: SiteStructure
  ): Promise<PageInfo | null> => {
    if (this.siteStore.experiments.enabled(SPECS.RouterBreadcrumbsInProductPage)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const page = await getReferringPage({site: this.siteStore.siteApis} as any, this.navigationContext);
      if (page) {
        const notHomepage = page.pageId !== homepage.id;
        const notSelf = !this.isCurrentPage(page.pageId);
        const isValid = notHomepage && notSelf;
        if (isValid) {
          return {
            url: page.url,
            name: page.title,
            id: page.pageId,
          };
        }
      }
    } else {
      const referringPageId = this.navigationContext.pageId;
      if (referringPageId) {
        return siteStructure.pages.find((p) => {
          const notHomepage = !p.isHomePage;
          const notSelf = !this.isCurrentPage(p.id);
          const matchRef = p.id === referringPageId;
          return notHomepage && notSelf && matchRef;
        });
      }
    }
  };

  public readonly getPagePath = async (): Promise<PagePath[]> => {
    const path = [];
    const siteStructure = await this.siteStore.siteApis.getSiteStructure({includePageId: true});

    const homepage = siteStructure.pages.find((p) => p.isHomePage);
    if (homepage) {
      path.push(this.toPagePath(homepage, true));
    }

    if (this.siteStore.isSSR()) {
      return path;
    }

    const referringPage = await this.getValidReferringPage(homepage, siteStructure);
    if (referringPage) {
      path.push(this.toPagePath(referringPage, true));
    }

    if (path.length === 0) {
      return [];
    }
    if (this.product) {
      path.push(this.toPagePath({name: this.product.name, url: null, id: null}, false));
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    path[0].name = this.translations.BREADCRUMBS_HOME;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    path[0].url = this.siteStore.location.baseUrl;
    return path;
  };
}
